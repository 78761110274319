<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_warein') " :icon=icon :subheading="$t('erp.lang_nav_warein')"
                    show-previous-button></page-title>
        <div class="app-main__inner">
            <good-receipt-edit-component />
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import GoodReceiptEditComponent from "../../../../components/erp/storage/goodreceipt/GoodReceiptEditComponent";


    export default {
        components: {
            GoodReceiptEditComponent,
            PageTitle,

        },
        props: ["id"],
        data: () => ({
            icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
        })
    }
</script>