<template>
    <v-container fluid>
        <v-card elevation="6">
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                <span>{{ '' || this.goodReceipt && this.goodReceipt.goodsReceiptName}}</span>

                <v-spacer/>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                @click="$router.push('/erp/storage/GoodsReceipt/details/' + $route.params.id)"
                                color="primary"
                                icon v-on="on">
                            <v-icon>remove_red_eye</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('erp.lang_details') }}</span>
                </v-tooltip>
            </v-card-title>

            <v-card-text>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-form lazy-validation ref="form">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :readonly="this.Protected"

                                                  autocomplete="off"
                                                  :label="$t('erp.lang_nameOfGoodsReceipt')"
                                                  outlined
                                                  required
                                                  v-model="form.GoodsReceiptName"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-select :items="storageSelect"
                                              :readonly="this.Protected"
                                              item-text="name"
                                              item-value="id"
                                              :label="$t('erp.lang_targetStore')"
                                              outlined
                                              required
                                              v-model="form.targetStorage"
                                    ></v-select>
                                </v-col>
                                <v-col class="ma-0 pt-0 pb-0" cols="12">
                                    <v-btn :disabled="this.loading || this.Protected" :loading="this.loading"
                                           @click="save" block
                                           class="ma-0"
                                           color="primary" large>
                                        {{this.$t('generic.lang_save')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12">

                        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                                :defaultKeySet="touchKeyboard.keySet"
                                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>
import {Events} from "../../../../plugins/events";
import {ENDPOINTS} from "../../../../config";
import mixin from "../../../../mixins/KeyboardMixIns";

export default {
        name: "GoodReceiptEditComponent",
        mixins: [mixin],
        data: () => {
            return {
                ENDPOINT: ENDPOINTS,
                goodReceipt: null,
                form: {},
                storageSelect: [],
                loading: false,
                Protected: false
            }
        },
        methods: {
            save() {
                this.loading = true;
                this.form.storageTransferIDs = this.$route.params.id;
                this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.EDIT, this.form).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_itemSuccessfulEdited'),
                            color: "success"
                        });
                        this.$router.push('/erp/storage/GoodsReceipt')

                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadStorages: function () {
                let self = this;
                self.axios.get(ENDPOINTS.ERP.STORAGES.GET)
                    .then((res) => {
                        if (res.status === 200) {
                            res.data.storageInformation.forEach((row) => {
                                this.storageSelect.push({name: row[1], id: parseInt(row[0])});
                            });
                        } else {
                            Events.$emit("showSnackbar", {
                                message: self.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            loadGoodReceiptDetails() {
                this.axios.post(this.ENDPOINT.ERP.GOODSRECEIPT.SHOW,
                    {
                        goodsReceiptUUID: this.$route.params.id,
                    }).then((res) => {
                    if (res.data.formfillData) {
                        this.goodReceipt = res.data.formfillData.textFields;
                        this.form.GoodsReceiptName = res.data.formfillData.textFields.goodsReceiptName;
                        this.form.targetStorage = res.data.formfillData.textFields.targetStorageID;
                        this.Protected = parseInt(res.data.protected) == 1;
                    }


                }).catch((err) => {
                    this.loadingSelect = false;
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                })
            }
        }
        ,
        mounted() {
            this.loadGoodReceiptDetails();
            this.loadStorages();
        }
    }
</script>

<style scoped>

</style>